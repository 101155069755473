<script>
import StandForm from "@/views/menu/stand/standForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    standService: "standService",
    eventService: "eventService",
    fileUploadService: "fileUploadService",
    visitorLevelService: "visitorLevelService",
    toastService: "toastService",
  }
})
export default class StandNew extends mixins(StandForm) {
  formId = "new-stand-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      this.model.localizations.forEach(loc => {
        if(!loc.videoPlatformType){
          loc.videoPlatformType = "Default";
        }
      });
      await this.standService.create(this.model);
      this.$router.push("/stand/list");
      this.toastService.success(this.translations.success.stand_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.stands_new;
  }

  async afterCreate () {
    this.setVisitorLevels(await this.visitorLevelService.enabledLevels(this.model.id));
    this.model = {
      idPavilion: null,
      code: null,
      displayOrder: 0,
      drawerEnabled: true,
      localizations: [],
      visitorLevels: this.defaultVisitorLevelsValue(),
      tagsIds: []
    };
    this.isReady = true;
  }
}
</script>
